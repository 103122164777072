.toolbar {
    background-color: #112f75;
    border-bottom: 1px solid black;
    padding-top: 1rem;
    padding-bottom: 1rem;
}


.searchField {
    background-color: #fff;
    border-radius: 4px;
  }

  .cirlceProgress {
    color: #ffffff !important;
  }