.card {
    border: 1px solid #112f7580 !important;
    transition: all 0.4s ease !important;
    
}

.card:global(:hover) {
    background-color: #112f75;
    color: white;
    border: 1px solid white;
}